import api from "@/api/utils.api.js";

const state = () => ({});

const getters = {};

const mutations = {};

const actions = {
  async restartDeviceService({ dispatch }, { path }) {
    try {
      await api.post(path, null);
    } catch (e) {
      if (e.response.status === 401) {
        dispatch("security/logout", null, { root: true });
      } else {
        dispatch("errors/createErrorMessage", e, { root: true });
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
