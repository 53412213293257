import { connect } from "vuex-connect";
import ReportGroupEdit from "./ReportGroupEdit";

const URL = "/api/v1/reportGroup";

export default connect({
  stateToProps: {
    object: (state) => state.reportGroup.object,
    saved: (state) => state.reportGroup.saved,
  },
  methodsToEvents: {
    edit({ dispatch }, object) {
      dispatch("reportGroup/editObject", object);
    },
    persist({ dispatch }) {
      dispatch("reportGroup/persistObject", { path: URL });
    },
    cancelEdit({ dispatch }) {
      dispatch("reportGroup/cancelEdit");
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("reportGroup/getById", { id: this.$route.query.id });
      await dispatch("mainTitle/setTitle", "Редактирование группы");
    },
  },
})("ReportGroupEdit", ReportGroupEdit);
