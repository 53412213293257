import { connect } from "vuex-connect";
import Dashboard from "./Dashboard";

const URL_RESTART_DEVICE_SERVICE = "/api/v1/system/restart-device-service";

export default connect({
  stateToProps: {},
  methodsToEvents: {
    restartDeviceService({ dispatch }) {
      dispatch("system/restartDeviceService", {
        path: URL_RESTART_DEVICE_SERVICE,
      });
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("mainTitle/setTitle", "Панель управления");
    },
  },
})("Dashboard", Dashboard);
