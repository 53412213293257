import Vue from "vue";

Vue.component("MainTitle", () =>
  import("@/components/mainTitle/MainTitleConnect")
);
Vue.component("TableBanner", () => import("@/components/TableBanner"));
Vue.component("FormBanner", () => import("@/components/FormBanner"));
Vue.component("GraphSelect", () =>
  import("@/components/graphSelect/GraphSelectConnect")
);
Vue.component("CardTypeSelect", () =>
  import("@/components/cardTypeSelect/CardTypeSelectConnect")
);
Vue.component("AttractionDeviceSelect", () =>
  import("@/components/attractionDeviceSelect/AttractionDeviceSelectConnect")
);
Vue.component("AttractionTableMultiSelect", () =>
  import(
    "@/components/attractionTableMultiSelect/AttractionTableMultiSelectConnect"
  )
);
Vue.component("UserTableMultiSelect", () =>
  import("@/components/userTableMultiSelect/UserTableMultiSelectConnect")
);
Vue.component("AppErrorBanner", () =>
  import("@/components/appErrorBanner/AppErrorBannerConnect")
);
