<template>
  <v-container fluid>
    <FormBanner
      returnLink="Attraction"
      :saved="saved"
      :valid="valid"
      :parentName="parentName"
      @persist="$emit('persist')"
      @cancelEdit="$emit('cancelEdit')"
    />
    <v-container fluid>
      <v-form v-model="valid" ref="form">
        <AttractionDeviceSelect
          :attractionDeviceId="item.attractionDeviceId"
          @selectAttractionDevice="
            $emit('edit', { key: 'attractionDeviceId', value: $event.id })
          "
        />
        <v-text-field
          :value="item.mode"
          label="Режим"
          outlined
          rounded
          type="number"
          min="0"
          inputmode="numeric"
          pattern="^[-\d]\d*$"
          step="1"
          @input="$emit('edit', { key: 'mode', value: $event })"
        />
        <v-text-field
          :value="item.name"
          label="Наименование"
          outlined
          rounded
          :rules="requiredRule"
          @input="$emit('edit', { key: 'name', value: $event })"
        />
        <v-textarea
          counter
          rows="4"
          :value="item.formatName"
          label="Форматированное на дисплее"
          outlined
          rounded
          :rules="requiredRule"
          @input="$emit('edit', { key: 'formatName', value: $event })"
        />
        <v-switch
          :input-value="item.enabled"
          label="Включен"
          @change="$emit('edit', { key: 'enabled', value: $event })"
        />
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import { requiredRule } from "@/validation/input-rules.js";
import _ from "lodash";

export default {
  props: {
    object: Object,
    saved: Boolean,
    parentName: String,
  },
  data: () => ({
    valid: false,
    requiredRule: [requiredRule.bind(this)],
  }),
  computed: {
    item: ({ object }) =>
      _.isEmpty(object)
        ? {
            id: null,
            name: null,
            formatName: null,
            enabled: false,
            attractionDeviceId: null,
            attractionDeviceIpAddress: null,
            attractionDevicePort: null,
            attractionDeviceDeviceAddress: null,
            mode: null,
          }
        : object,
  },
};
</script>
