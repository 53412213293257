import { connect } from "vuex-connect";
import UserEdit from "./UserEdit";

const URL = "/api/v1/principal";

export default connect({
  stateToProps: {
    object: (state) => state.users.object,
    saved: (state) => state.users.saved,
  },
  methodsToEvents: {
    edit({ dispatch }, object) {
      dispatch("users/editObject", object);
    },
    persist({ dispatch }) {
      dispatch("users/persistObject", { path: URL });
    },
    cancelEdit({ dispatch }) {
      dispatch("users/cancelEdit");
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("users/getById", { id: this.$route.query.id });
      await dispatch("mainTitle/setTitle", "Редактирование пользователя");
    },
  },
})("UserEdit", UserEdit);
