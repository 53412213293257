const state = () => ({
  title: "Admin-Park",
});

const getters = {};

const mutations = {
  SET_TITLE(state, title) {
    state.title = title;
  },
};

const actions = {
  async setTitle({ commit }, title) {
    await commit("SET_TITLE", title);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
