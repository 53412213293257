import { connect } from "vuex-connect";
import CardTypeBonusSettings from "./CardTypeBonusSettings";

const URL = "/api/v1/cardTypeBonusSettings";

export default connect({
  stateToProps: {
    objectList: (state) => state.cardTypeBonusSettings.objects,
    parentId: (state) => state.cardTypeBonusSettings.parentId,
  },
  methodsToEvents: {
    deleteItem: function ({ dispatch }, item) {
      dispatch("cardTypeBonusSettings/delete", { path: URL, object: item });
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("cardTypeBonusSettings/get", {
        path: URL,
        object: this.$route.query.parentId,
      });
      await dispatch("mainTitle/setTitle", "Настройка бонусов");
    },
  },
})("CardTypeBonusSettings", CardTypeBonusSettings);
