import Vue from "vue";
import VueRouter from "vue-router";

import LoginConnect from "@/views/login/LoginConnect";
import UserConnect from "@/views/users/UserConnect";
import UserEditConnect from "@/views/users/UserEditConnect";
import CashierConnect from "@/views/cashier/CashierConnect";
import CashierEditConnect from "@/views/cashier/CashierEditConnect";
import DashboardConnect from "@/views/dashboard/DashboardConnect";
import GraphConnect from "@/views/graph/GraphConnect";
import GraphEditConnect from "@/views/graph/GraphEditConnect";
import CardTypeConnect from "@/views/cardType/CardTypeConnect";
import CardTypeEditConnect from "@/views/cardType/CardTypeEditConnect";
import CardTypeSaleSettingsConnect from "@/views/cardType/cardTypeSaleSettings/CardTypeSaleSettingsConnect";
import CardTypeSaleSettingsEditConnect from "@/views/cardType/cardTypeSaleSettings/CardTypeSaleSettingsEditConnect";
import CardTypeBonusSettingsConnect from "@/views/cardType/cardTypeBonusSettings/CardTypeBonusSettingsConnect";
import CardTypeBonusSettingsEditConnect from "@/views/cardType/cardTypeBonusSettings/CardTypeBonusSettingsEditConnect";
import AttractionDeviceConnect from "@/views/attractionDevice/AttractionDeviceConnect";
import AttractionDeviceEditConnect from "@/views/attractionDevice/AttractionDeviceEditConnect";
import AttractionConnect from "@/views/attraction/AttractionConnect";
import AttractionEditConnect from "@/views/attraction/AttractionEditConnect";
import AttractionSettingsConnect from "@/views/attraction/attractionSettings/AttractionSettingsConnect";
import AttractionSettingsEditConnect from "@/views/attraction/attractionSettings/AttractionSettingsEditConnect";
import ReportGroupConnect from "@/views/reportGroup/ReportGroupConnect";
import ReportGroupEditConnect from "@/views/reportGroup/ReportGroupEditConnect";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: DashboardConnect,
    alias: "/dashboard",
  },
  {
    path: "/login",
    name: "Login",
    component: LoginConnect,
  },
  {
    path: "/users",
    name: "Users",
    component: UserConnect,
  },
  {
    path: "/userEdit",
    name: "UserEdit",
    component: UserEditConnect,
  },
  {
    path: "/cashier",
    name: "Cashier",
    component: CashierConnect,
  },
  {
    path: "/cashierEdit",
    name: "CashierEdit",
    component: CashierEditConnect,
  },
  {
    path: "/graph",
    name: "Graph",
    component: GraphConnect,
  },
  {
    path: "/graphEdit",
    name: "GraphEdit",
    component: GraphEditConnect,
  },
  {
    path: "/cardType",
    name: "CardType",
    component: CardTypeConnect,
  },
  {
    path: "/cardTypeEdit",
    name: "CardTypeEdit",
    component: CardTypeEditConnect,
  },
  {
    path: "/cardTypeSaleSettings",
    name: "CardTypeSaleSettings",
    component: CardTypeSaleSettingsConnect,
  },
  {
    path: "/cardTypeSaleSettingsEdit",
    name: "CardTypeSaleSettingsEdit",
    component: CardTypeSaleSettingsEditConnect,
  },
  {
    path: "/cardTypeBonusSettings",
    name: "CardTypeBonusSettings",
    component: CardTypeBonusSettingsConnect,
  },
  {
    path: "/cardTypeBonusSettingsEdit",
    name: "CardTypeBonusSettingsEdit",
    component: CardTypeBonusSettingsEditConnect,
  },
  {
    path: "/attractionDevice",
    name: "AttractionDevice",
    component: AttractionDeviceConnect,
  },
  {
    path: "/attractionDeviceEdit",
    name: "AttractionDeviceEdit",
    component: AttractionDeviceEditConnect,
  },
  {
    path: "/attraction",
    name: "Attraction",
    component: AttractionConnect,
  },
  {
    path: "/attractionEdit",
    name: "AttractionEdit",
    component: AttractionEditConnect,
  },
  {
    path: "/attractionSettings",
    name: "AttractionSettings",
    component: AttractionSettingsConnect,
  },
  {
    path: "/attractionSettingsEdit",
    name: "AttractionSettingsEdit",
    component: AttractionSettingsEditConnect,
  },
  {
    path: "/reportGroup",
    name: "ReportGroup",
    component: ReportGroupConnect,
  },
  {
    path: "/reportGroupEdit",
    name: "ReportGroupEdit",
    component: ReportGroupEditConnect,
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && localStorage.getItem("token") == null)
    next({ name: "Login" });
  else next();

  if (to.name === "Login" && localStorage.getItem("token") !== null)
    next({ name: "Dashboard" });
});

export default router;
