import { connect } from "vuex-connect";
import User from "./User";

const URL = "/api/v1/principal";

export default connect({
  stateToProps: {
    objectList: (state) => state.users.objects,
  },
  methodsToEvents: {
    deleteItem: function ({ dispatch }, item) {
      dispatch("users/delete", { path: URL, object: item });
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("users/get", { path: URL });
      await dispatch("mainTitle/setTitle", "Пользователи");
    },
  },
})("User", User);
