import { connect } from "vuex-connect";
import Cashier from "./Cashier";

const URL = "api/v1/cashier";

export default connect({
  stateToProps: {
    objectList: (state) => state.cashier.objects,
  },
  methodsToEvents: {
    deleteItem: function ({ dispatch }, item) {
      dispatch("cashier/delete", { path: URL, object: item });
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("cashier/get", { path: URL });
      await dispatch("mainTitle/setTitle", "Кассы");
    },
  },
})("Cashier", Cashier);
