import { connect } from "vuex-connect";
import AttractionDevice from "./AttractionDevice";

const URL = "api/v1/attractionDevice";

export default connect({
  stateToProps: {
    objectList: (state) => state.attractionDevice.objects,
  },
  methodsToEvents: {
    deleteItem: function ({ dispatch }, item) {
      dispatch("attractionDevice/delete", { path: URL, object: item });
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("attractionDevice/get", { path: URL });
      await dispatch("mainTitle/setTitle", "Устройства");
    },
  },
})("AttractionDevice", AttractionDevice);
