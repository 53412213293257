import axios from "axios";

export default {
  async get(path, payload) {
    if (payload)
      return await axios.get(path, {
        params: {
          parentId: payload,
        },
      });
    else return await axios.get(path);
  },

  async put(path, payload) {
    return await axios.put(path, payload);
  },

  async post(path, payload) {
    return await axios.post(path, payload);
  },

  async delete(path, payload) {
    return await axios.delete(path, { data: payload });
  },
};
