import { connect } from "vuex-connect";
import Attraction from "./Attraction";

const URL = "api/v1/attraction";

export default connect({
  stateToProps: {
    objectList: (state) => state.attraction.objects,
  },
  methodsToEvents: {
    deleteItem: function ({ dispatch }, item) {
      dispatch("attraction/delete", { path: URL, object: item });
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("attraction/get", { path: URL });
      await dispatch("mainTitle/setTitle", "Аттракционы");
    },
  },
})("Attraction", Attraction);
