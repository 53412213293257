import { connect } from "vuex-connect";
import AttractionSettings from "./AttractionSettings";

const URL = "/api/v1/attractionSettings";

export default connect({
  stateToProps: {
    objectList: (state) => state.attractionSettings.objects,
    parentId: (state) => state.attractionSettings.parentId,
    parentName: (state) => state.attractionSettings.parentName,
  },
  methodsToEvents: {
    deleteItem: function ({ dispatch }, item) {
      dispatch("attractionSettings/delete", { path: URL, object: item });
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("attractionSettings/get", {
        path: URL,
        object: this.$route.query.parentId,
        parentName: this.$route.query.parentName,
      });
      await dispatch("mainTitle/setTitle", "Настройка аттракционов");
    },
  },
})("AttractionSettings", AttractionSettings);
