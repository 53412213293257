import { connect } from "vuex-connect";
import GraphEdit from "./GraphEdit";

const URL = "/api/v1/graph";

export default connect({
  stateToProps: {
    object: (state) => state.graph.object,
    saved: (state) => state.graph.saved,
  },
  methodsToEvents: {
    edit({ dispatch }, object) {
      dispatch("graph/editObject", object);
    },
    persist({ dispatch }) {
      dispatch("graph/persistObject", { path: URL });
    },
    cancelEdit({ dispatch }) {
      dispatch("graph/cancelEdit");
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("graph/getById", { id: this.$route.query.id });
      await dispatch("mainTitle/setTitle", "Редактирование графика");
    },
  },
})("GraphEdit", GraphEdit);
