import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { enableAllPlugins } from "immer";
import axios from "axios";
import VueConfirmDialog from "vue-confirm-dialog";

enableAllPlugins();
Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

if (process.env.NODE_ENV !== "production") {
  axios.defaults.withCredentials = true;
  Vue.prototype.$http.defaults.baseURL = `${process.env.VUE_APP_HOST}/`;
}

const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common.Authorization = "Bearer " + token;
}

Vue.config.devtools = process.env.NODE_ENV !== "production";
Vue.config.productionTip = false;

import "@/assets/components";
import "@/assets/main.css";

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
