var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('TableBanner',{attrs:{"addLink":"AttractionEdit"}}),_c('v-switch',{attrs:{"label":"Отображать выключенные"},model:{value:(_vm.showDisabled),callback:function ($$v) {_vm.showDisabled=$$v},expression:"showDisabled"}}),_c('v-data-table',{staticClass:"transparent",attrs:{"headers":_vm.headers,"items":_vm.objectList,"dense":"","disable-pagination":"","hide-default-footer":"","item-key":"id","multi-sort":""},scopedSlots:_vm._u([{key:`item.enabled`,fn:function({ item }){return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})]}},{key:`item.action`,fn:function({ item }){return [_c('v-btn',{attrs:{"to":{
          name: 'AttractionEdit',
          query: { id: item.id, parentName: item.name },
        },"text":""}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('deleteItem', item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}},{key:`item.attractionSettings`,fn:function({ item }){return [_c('v-btn',{attrs:{"to":{
          name: 'AttractionSettings',
          query: { parentId: item.id, parentName: item.name },
        },"text":""}},[_c('v-icon',[_vm._v("mdi-cog-outline")])],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }