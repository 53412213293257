<template>
  <v-container fluid>
    <FormBanner
      returnLink="CardType"
      :saved="saved"
      :valid="valid"
      @persist="$emit('persist')"
      @cancelEdit="$emit('cancelEdit')"
    />
    <v-container fluid>
      <v-form v-model="valid" ref="form">
        <v-text-field
          :value="item.name"
          label="Наименование"
          outlined
          rounded
          :rules="requiredRule"
          @input="$emit('edit', { key: 'name', value: $event })"
        />
        <v-switch
          :input-value="item.mainType"
          label="Основной тип"
          @change="$emit('edit', { key: 'mainType', value: $event })"
        />
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import { requiredRule } from "@/validation/input-rules.js";
import _ from "lodash";

export default {
  props: {
    object: Object,
    saved: Boolean,
  },
  data: () => ({
    valid: false,
    requiredRule: [requiredRule.bind(this)],
  }),
  computed: {
    item: ({ object }) =>
      _.isEmpty(object)
        ? {
            id: null,
            name: null,
            mainType: null,
          }
        : object,
  },
};
</script>
