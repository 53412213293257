import { connect } from "vuex-connect";
import CardTypeSaleSettingsEdit from "./CardTypeSaleSettingsEdit";

const URL = "/api/v1/cardTypeSaleSettings";

export default connect({
  stateToProps: {
    object: (state) => state.cardTypeSaleSettings.object,
    saved: (state) => state.cardTypeSaleSettings.saved,
    parentId: (state) => state.cardTypeSaleSettings.parentId,
  },
  methodsToEvents: {
    edit({ dispatch }, object) {
      dispatch("cardTypeSaleSettings/editObject", object);
    },
    persist({ dispatch }) {
      dispatch("cardTypeSaleSettings/persistObject", { path: URL });
    },
    cancelEdit({ dispatch }) {
      dispatch("cardTypeSaleSettings/cancelEdit");
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("cardTypeSaleSettings/getById", {
        parentId: this.$route.query.parentId,
        id: this.$route.query.id,
      });
      await dispatch("mainTitle/setTitle", "Редактирование настройки продажи");
    },
  },
})("CardTypeSaleSettingsEdit", CardTypeSaleSettingsEdit);
