<template>
  <v-container fluid>
    <TableBanner addLink="GraphEdit" />
    <v-data-table
      :headers="headers"
      :items="objectList"
      class="transparent"
      dense
      disable-pagination
      hide-default-footer
      item-key="id"
      multi-sort
    >
      <template #[`item.action`]="{ item }">
        <v-btn :to="{ name: 'GraphEdit', query: { id: item.id } }" text>
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn text @click="$emit('deleteItem', item)">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: {
    objectList: Array,
  },
  data: () => ({
    headers: [
      { text: "Наименование", value: "name" },
      { text: "Приоритет", value: "priority" },
      {
        text: "Редактирование Удаление",
        value: "action",
        sortable: false,
        align: "right",
      },
    ],
  }),
};
</script>
