import { connect } from "vuex-connect";
import AttractionDeviceEdit from "./AttractionDeviceEdit";

const URL = "/api/v1/attractionDevice";

export default connect({
  stateToProps: {
    object: (state) => state.attractionDevice.object,
    saved: (state) => state.attractionDevice.saved,
  },
  methodsToEvents: {
    edit({ dispatch }, object) {
      dispatch("attractionDevice/editObject", object);
    },
    persist({ dispatch }) {
      dispatch("attractionDevice/persistObject", { path: URL });
    },
    cancelEdit({ dispatch }) {
      dispatch("attractionDevice/cancelEdit");
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("attractionDevice/getById", { id: this.$route.query.id });
      await dispatch("mainTitle/setTitle", "Редактирование устройств");
    },
  },
})("AttractionDeviceEdit", AttractionDeviceEdit);
