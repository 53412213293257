import { connect } from "vuex-connect";
import Login from "./Login";

export default connect({
  stateToProps: {
    errorText: (state) => state.security.errorText,
  },
  methodsToEvents: {
    login: function ({ dispatch }, { username, password }) {
      dispatch("security/login", { username, password });
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("mainTitle/setTitle", "Авторизация");
    },
  },
})("Login", Login);
