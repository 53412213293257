<template>
  <v-container fluid>
    <TableBanner addLink="AttractionDeviceEdit" />
    <v-data-table
      :headers="headers"
      :items="objectListComputed"
      class="transparent"
      dense
      disable-pagination
      hide-default-footer
      item-key="id"
      multi-sort
      group-by="socket"
      disable-sort
      :sort-by="['socket', 'deviceAddress']"
    >
      <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
        <th :colspan="headers.length">
          <v-icon @click="toggle">
            {{ isOpen ? "mdi-minus" : "mdi-plus" }}
          </v-icon>
          {{ items[0].socket }}
        </th>
      </template>

      <template #[`item.indicateEntrance`]="{ item }">
        <v-simple-checkbox
          v-model="item.indicateEntrance"
          disabled
        ></v-simple-checkbox>
      </template>

      <template #[`item.action`]="{ item }">
        <v-btn
          :to="{ name: 'AttractionDeviceEdit', query: { id: item.id } }"
          text
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn text @click="$emit('deleteItem', item)">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: {
    objectList: Array,
  },
  data: () => ({
    socketDelimiter: ":",
    headers: [
      { text: "Хост", value: "socket" },
      { text: "Устройство", value: "deviceAddress" },
      { text: "Количество режимов", value: "modeCount" },
      { text: "Максимум проходов", value: "maxEntranceCount" },
      { text: "Задержка", value: "timeout" },
      { text: "Яркость", value: "ledBrightness" },
      { text: "Фиксация", value: "indicateEntrance" },
      {
        text: "Редактирование Удаление",
        value: "action",
        sortable: false,
        align: "right",
      },
    ],
  }),
  computed: {
    objectListComputed() {
      return this.objectList.map((el) => ({
        ...el,
        socket: el.ipAddress + " : " + el.port,
      }));
    },
  },
};
</script>
