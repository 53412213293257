import { connect } from "vuex-connect";
import Graph from "./Graph";

const URL = "api/v1/graph";

export default connect({
  stateToProps: {
    objectList: (state) => state.graph.objects,
  },
  methodsToEvents: {
    deleteItem: function ({ dispatch }, item) {
      dispatch("graph/delete", { path: URL, object: item });
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("graph/get", { path: URL });
      await dispatch("mainTitle/setTitle", "Графики");
    },
  },
})("Graph", Graph);
