<template>
  <form class="box" @submit.prevent="login">
    <v-alert v-if="errorText != null" outlined type="error">
      {{ errorText }}
    </v-alert>

    <input v-model="username" name="" placeholder="Пользователь" type="text" />
    <input v-model="password" name="" placeholder="Пароль" type="password" />
    <input name="" type="submit" value="Войти" />
  </form>
</template>

<script>
export default {
  props: {
    errorText: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    username: "",
    password: "",
  }),
  methods: {
    login: function () {
      const username = this.username;
      const password = this.password;
      try {
        this.$emit("login", { username, password });
      } catch {
        console.log("Auth error");
      }
    },
  },
};
</script>

<style>
.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.box input[type="text"],
.box input[type="password"] {
  display: block;
  margin: 20px auto;
  text-align: center;
  padding: 14px 10px;
  outline: none;
  border-radius: 24px;
  transition: 0.25s;
  border: 2px solid;
}

.box input[type="text"]:focus,
.box input[type="password"]:focus {
  width: 280px;
}

.box input[type="submit"] {
  border: 0;
  background: none;
  display: block;
  margin: 20px auto;
  text-align: center;
  border: 2px solid;
  padding: 14px 40px;
  outline: none;
  border-radius: 24px;
  transition: 0.25s;
  cursor: pointer;
}
</style>
