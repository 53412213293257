<template>
  <v-container fluid>
    <TableBanner
      returnLink="CardType"
      addLink="CardTypeSaleSettingsEdit"
      :parentId="parentId"
    />
    <v-data-table
      :headers="headers"
      :items="objectList"
      class="transparent"
      dense
      disable-pagination
      hide-default-footer
      item-key="id"
      multi-sort
    >
      <template #[`item.action`]="{ item }">
        <v-btn
          :to="{
            name: 'CardTypeSaleSettingsEdit',
            query: { parentId: parentId, id: item.id },
          }"
          text
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn text @click="$emit('deleteItem', item)">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </template>
      <template #[`item.canSale`]="{ item }">
        <v-simple-checkbox v-model="item.canSale" disabled></v-simple-checkbox>
      </template>
      <template #[`item.canAddFunds`]="{ item }">
        <v-simple-checkbox
          v-model="item.canAddFunds"
          disabled
        ></v-simple-checkbox>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: {
    objectList: Array,
    parentId: String,
  },
  data: () => ({
    headers: [
      { text: "График", value: "graphName" },
      { text: "Цена продажи", value: "price" },
      { text: "Сумма автоначисления", value: "onSaleSum" },
      { text: "Разрешена продажа", value: "canSale" },
      { text: "Разрешено пополнение", value: "canAddFunds" },
      {
        text: "Редактирование Удаление",
        value: "action",
        sortable: false,
        align: "right",
      },
    ],
  }),
};
</script>
