import { connect } from "vuex-connect";
import CardType from "./CardType";

const URL = "api/v1/cardType";

export default connect({
  stateToProps: {
    objectList: (state) => state.cardType.objects,
  },
  methodsToEvents: {
    deleteItem: function ({ dispatch }, item) {
      dispatch("cardType/delete", { path: URL, object: item });
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("cardType/get", { path: URL });
      await dispatch("mainTitle/setTitle", "Типы карт");
    },
  },
})("CardType", CardType);
