<template>
  <v-container fluid>
    <FormBanner
      returnLink="CardTypeBonusSettings"
      :parentId="parentId"
      :saved="saved"
      :valid="valid"
      @persist="$emit('persist')"
      @cancelEdit="$emit('cancelEdit')"
    />
    <v-container fluid>
      <v-form v-model="valid" ref="form">
        <GraphSelect
          :graphId="item.graphId"
          @selectGraph="$emit('edit', { key: 'graphId', value: $event.id })"
        />
        <v-text-field
          :value="item.bonus"
          label="Бонусы"
          outlined
          rounded
          type="number"
          min="0"
          inputmode="numeric"
          pattern="^[-\d]\d*$"
          step="1"
          @input="$emit('edit', { key: 'bonus', value: $event })"
        />
        <v-text-field
          :value="item.startInterval"
          label="Начальный"
          outlined
          rounded
          type="number"
          min="0"
          inputmode="numeric"
          pattern="[0-9]*"
          @input="$emit('edit', { key: 'startInterval', value: $event })"
        />
        <v-text-field
          :value="item.endInterval"
          label="Конечный"
          outlined
          rounded
          type="number"
          min="0"
          inputmode="numeric"
          pattern="[0-9]*"
          @input="$emit('edit', { key: 'endInterval', value: $event })"
        />
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import { requiredRule } from "@/validation/input-rules.js";
import _ from "lodash";

export default {
  props: {
    parentId: String,
    object: Object,
    saved: Boolean,
  },
  data: () => ({
    valid: false,
    requiredRule: [requiredRule.bind(this)],
  }),
  computed: {
    item: ({ object }) =>
      _.isEmpty(object)
        ? {
            id: null,
            cardTypeId: null,
            cardTypeName: null,
            cardTypeMainType: false,
            graphId: null,
            graphName: null,
            graphPriority: null,
            bonus: 0,
            startInterval: 0.0,
            endInterval: 0.0,
          }
        : object,
  },
  watch: {
    item(val) {
      if (val.cardTypeId == null)
        this.$emit("edit", { key: "cardTypeId", value: this.parentId });
      if (val.bonus == null) this.$emit("edit", { key: "bonus", value: 0 });
      if (val.startInterval == null)
        this.$emit("edit", { key: "startInterval", value: 0.0 });
      if (val.endInterval == null)
        this.$emit("edit", { key: "endInterval", value: 0.0 });
    },
  },
};
</script>
