import { connect } from "vuex-connect";
import ReportGroup from "./ReportGroup";

const URL = "api/v1/reportGroup";

export default connect({
  stateToProps: {
    objectList: (state) => state.reportGroup.objects,
  },
  methodsToEvents: {
    deleteItem: function ({ dispatch }, item) {
      dispatch("reportGroup/delete", { path: URL, object: item });
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("reportGroup/get", { path: URL });
      await dispatch("mainTitle/setTitle", "Группы отчетности");
    },
  },
})("ReportGroup", ReportGroup);
