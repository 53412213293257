<template>
  <v-container fluid>
    <v-card elevation="0" outlined class="rounded-xl">
      <v-card-title>
        <v-combobox
          :items="graphTypes"
          :value="getComboboxValue(item.graphType, this.graphTypes)"
          item-text="name"
          item-value="id"
          label="Тип графика"
          outlined
          rounded
          @input="
            sendGraphSettings({
              key: 'graphType',
              value: getComboboxId($event),
            })
          "
        />
      </v-card-title>

      <v-card-text>
        <v-row v-if="item.graphType === 'YEAR' || item.graphType === 'MONTH'">
          <v-col cols="12" md="4">
            <v-text-field
              v-if="item.graphType === 'YEAR' || item.graphType === 'MONTH'"
              :value="item.startDay"
              label="Число месяца (с)"
              outlined
              rounded
              type="number"
              min="1"
              max="31"
              inputmode="numeric"
              pattern="[0-9]*"
              :rules="dayRules"
              @input="sendGraphSettings({ key: 'startDay', value: $event })"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-if="item.graphType === 'YEAR' || item.graphType === 'MONTH'"
              :value="item.endDay"
              label="Число месяца (по)"
              outlined
              rounded
              type="number"
              min="1"
              max="31"
              inputmode="numeric"
              pattern="[0-9]*"
              :rules="dayRules"
              @input="sendGraphSettings({ key: 'endDay', value: $event })"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-combobox
              v-if="item.graphType === 'YEAR'"
              :items="months"
              :value="getComboboxValue(item.month, this.months)"
              item-text="name"
              item-value="id"
              label="Месяц"
              outlined
              rounded
              @input="
                sendGraphSettings({
                  key: 'month',
                  value: getComboboxId($event),
                })
              "
            />
          </v-col>
        </v-row>

        <v-row v-if="item.graphType === 'WEEK'">
          <v-col cols="12" md="4">
            <v-combobox
              v-if="item.graphType === 'WEEK'"
              :items="weekDays"
              :value="getComboboxValue(item.startWeekday, this.weekDays)"
              item-text="name"
              item-value="id"
              label="День недели (с)"
              outlined
              rounded
              @input="
                sendGraphSettings({
                  key: 'startWeekday',
                  value: getComboboxId($event),
                })
              "
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-combobox
              v-if="item.graphType === 'WEEK'"
              :items="weekDays"
              :value="getComboboxValue(item.endWeekday, this.weekDays)"
              item-text="name"
              item-value="id"
              label="День недели (по)"
              outlined
              rounded
              @input="
                sendGraphSettings({
                  key: 'endWeekday',
                  value: getComboboxId($event),
                })
              "
            />
          </v-col>
        </v-row>

        <v-row v-if="item.graphType === 'INTERVAL'">
          <v-col cols="12" md="4">
            <v-text-field
              v-if="item.graphType === 'INTERVAL'"
              :value="item.startDate"
              label="Дата (с)"
              outlined
              rounded
              type="date"
              :rules="requiredRules"
              @input="sendGraphSettings({ key: 'startDate', value: $event })"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-if="item.graphType === 'INTERVAL'"
              :value="item.endDate"
              label="Дата (по)"
              outlined
              rounded
              type="date"
              :rules="requiredRules"
              @input="sendGraphSettings({ key: 'endDate', value: $event })"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              :value="item.startTime"
              label="Время (с)"
              outlined
              rounded
              type="time"
              :rules="requiredRules"
              @input="
                sendGraphSettings({ key: 'startTime', value: $event + ':00' })
              "
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              :value="item.endTime"
              label="Время (по)"
              outlined
              rounded
              clearable
              type="time"
              @input="
                sendGraphSettings({
                  key: 'endTime',
                  value: $event != null ? $event + ':00' : null,
                })
              "
            />
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-btn outlined rounded @click="deleteGraphSettings('new')">
            <v-icon>mdi-delete-outline</v-icon>
            <span>Удалить настройку</span>
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import _ from "lodash";
import Vue from "vue";

export default {
  name: "GraphSettings",
  props: {
    index: Number,
    graphSettings: Object,
  },
  data: () => ({
    graphTypes: [
      { id: "INTERVAL", name: "Интервал" },
      { id: "YEAR", name: "Повтор (Год)" },
      { id: "MONTH", name: "Повтор (Месяц)" },
      { id: "WEEK", name: "Повтор (Неделя)" },
      { id: "DAY", name: "Повтор (День)" },
    ],
    months: [
      { id: 1, name: "Январь" },
      { id: 2, name: "Февраль" },
      { id: 3, name: "Март" },
      { id: 4, name: "Апрель" },
      { id: 5, name: "Май" },
      { id: 6, name: "Июнь" },
      { id: 7, name: "Июль" },
      { id: 8, name: "Август" },
      { id: 9, name: "Сентябрь" },
      { id: 10, name: "Октябрь" },
      { id: 11, name: "Ноябрь" },
      { id: 12, name: "Декабрь" },
    ],
    weekDays: [
      { id: 1, name: "Понедельник" },
      { id: 2, name: "Вторник" },
      { id: 3, name: "Среда" },
      { id: 4, name: "Четверг" },
      { id: 5, name: "Пятница" },
      { id: 6, name: "Суббота" },
      { id: 7, name: "Воскресенье" },
    ],
    requiredRules: [(v) => !!v || "Это поле необходимо заполнить"],
    dayRules: [
      (v) => !!v || "Это поле необходимо заполнить",
      (v) => (v && v >= 1) || "Это поле должно быть больше 0",
      (v) => (v && v <= 31) || "Это поле должно быть меньше 32",
    ],
  }),
  computed: {
    item: ({ graphSettings }) =>
      _.isEmpty(graphSettings)
        ? {
            id: null,
            graphType: null,
            startDate: null,
            endDate: null,
            startDay: null,
            endDay: null,
            month: null,
            startWeekday: null,
            endWeekday: null,
            startTime: null,
            endTime: null,
          }
        : graphSettings,
  },
  methods: {
    getComboboxValue(id, list) {
      return id ? list.find((e) => e.id === id) : null;
    },
    getComboboxId(obj) {
      return obj.id;
    },
    sendGraphSettings(keyValue) {
      let obj = _.cloneDeep(this.item);
      Vue.set(obj, keyValue["key"], keyValue["value"]);
      obj.index = this.index;
      this.$emit("modifyGraphSettings", obj);
    },
    deleteGraphSettings() {
      Vue.$confirm({
        message: "Вы действительно хотите удалить настройку графика?",
        button: {
          yes: "Да",
          no: "Нет",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$emit("deleteGraphSettings", this.index);
          }
        },
      });
    },
  },
};
</script>
