import { connect } from "vuex-connect";
import AttractionSettingsEdit from "./AttractionSettingsEdit";

const URL = "/api/v1/attractionSettings";

export default connect({
  stateToProps: {
    object: (state) => state.attractionSettings.object,
    saved: (state) => state.attractionSettings.saved,
    parentId: (state) => state.attractionSettings.parentId,
    parentName: (state) => state.attractionSettings.parentName,
  },
  methodsToEvents: {
    edit({ dispatch }, object) {
      dispatch("attractionSettings/editObject", object);
    },
    persist({ dispatch }) {
      dispatch("attractionSettings/persistObject", { path: URL });
    },
    cancelEdit({ dispatch }) {
      dispatch("attractionSettings/cancelEdit");
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("attractionSettings/getById", {
        parentId: this.$route.query.parentId,
        id: this.$route.query.id,
        parentName: this.$route.query.parentName,
      });
      await dispatch(
        "mainTitle/setTitle",
        "Редактирование настройки аттракционов"
      );
    },
  },
})("AttractionSettingsEdit", AttractionSettingsEdit);
