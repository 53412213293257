<template>
  <v-container fluid>
    <FormBanner
      returnLink="Graph"
      :saved="saved"
      :valid="valid"
      @persist="$emit('persist')"
      @cancelEdit="$emit('cancelEdit')"
    />
    <v-container fluid>
      <v-form v-model="valid" ref="form">
        <v-text-field
          :value="item.name"
          label="Наименование"
          outlined
          rounded
          :rules="requiredRule"
          @input="$emit('edit', { key: 'name', value: $event })"
        />
        <v-text-field
          :value="item.priority"
          label="Приоритет"
          outlined
          rounded
          type="number"
          min="1"
          inputmode="numeric"
          pattern="[0-9]*"
          @input="$emit('edit', { key: 'priority', value: $event })"
        />

        <v-btn outlined rounded @click="modifyGraphSettings('new')">
          <v-icon>mdi-plus</v-icon>
          <span>Создать настройку</span>
        </v-btn>
        <GraphSettings
          v-for="(gs, index) in item.graphSettings"
          :key="gs.id"
          :index="index"
          :graphSettings="gs"
          @modifyGraphSettings="modifyGraphSettings($event)"
          @deleteGraphSettings="deleteGraphSettings($event)"
        />
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import { requiredRule } from "@/validation/input-rules.js";
import GraphSettings from "@/components/GraphSettings";
import _ from "lodash";
import Vue from "vue";

export default {
  props: {
    object: Object,
    saved: Boolean,
  },
  components: {
    GraphSettings,
  },
  data: () => ({
    valid: false,
    requiredRule: [requiredRule.bind(this)],
  }),
  computed: {
    item: ({ object }) =>
      _.isEmpty(object)
        ? {
            id: null,
            name: null,
            priority: null,
            graphSettings: [],
          }
        : object,
  },
  methods: {
    modifyGraphSettings(obj) {
      let graphSettingsCopy = _.cloneDeep(this.item.graphSettings);
      if (!graphSettingsCopy) graphSettingsCopy = [];
      if (obj === "new") graphSettingsCopy.push({});
      else Vue.set(graphSettingsCopy, obj.index, obj);
      this.$emit("edit", { key: "graphSettings", value: graphSettingsCopy });
    },
    deleteGraphSettings(deletingGraphSettingsIndex) {
      console.log(deletingGraphSettingsIndex);
      let graphSettingsCopy = _.cloneDeep(this.item.graphSettings);
      graphSettingsCopy.splice(deletingGraphSettingsIndex, 1);
      this.$emit("edit", { key: "graphSettings", value: graphSettingsCopy });
    },
  },
};
</script>
