<template>
  <v-container fluid>
    <v-btn @click="$emit('restartDeviceService')" outlined rounded>
      <span>Перезапустить сервис считывателей</span>
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: "Dashboard",
};
</script>
