import api from "@/api/utils.api.js";
import Vue from "vue";
import _ from "lodash";

const state = () => ({
  objects: [],
  object: {},
  objectPersisted: {},
  saved: true,
  parentId: null,
  parentName: null,
});

const getters = {};

const mutations = {
  SET_PARENT_ID(state, parentId) {
    state.parentId = parentId;
  },
  SET_PARENT_NAME(state, parentName) {
    state.parentName = parentName;
  },
  CLEAR_STATE(state) {
    state.objects = [];
  },
  SET_OBJECT(state, payload) {
    let object = state.objects.find((obj) => obj.id === payload);
    if (object) {
      state.object = object;
      state.objectPersisted = _.cloneDeep(object);
    } else state.object = {};
    state.saved = true;
  },
  EDIT_OBJECT(state, object) {
    Vue.set(state.object, object["key"], object["value"]);
    state.saved = _.isEqual(state.objectPersisted, state.object);
  },
  CANCEL_EDIT(state) {
    state.object = _.cloneDeep(state.objectPersisted);
    state.saved = true;
  },
  PERSIST_OBJECT(state) {
    state.objectPersisted = _.cloneDeep(state.object);
    state.saved = true;
  },
  SET_OBJECTS_LIST(state, payload) {
    state.objects = payload;
  },
  DELETE_OBJECT(state, payload) {
    let index = state.objects.findIndex((x) => x.id === payload.id);
    state.objects.splice(index, 1);
  },
};

const actions = {
  async getById({ commit }, { parentId, id, parentName }) {
    if (parentId) await commit("SET_PARENT_ID", parentId);

    if (parentName) await commit("SET_PARENT_NAME", parentName);
    else await commit("SET_PARENT_NAME", undefined);

    await commit("SET_OBJECT", id);
  },
  async editObject({ commit }, object) {
    await commit("EDIT_OBJECT", object);
  },
  async cancelEdit({ commit }) {
    await commit("CANCEL_EDIT");
  },
  async persistObject({ commit, state, dispatch }, { path }) {
    try {
      await api.put(path, state.object);
      commit("PERSIST_OBJECT");
    } catch (e) {
      if (e.response.status === 401) {
        dispatch("security/logout", null, { root: true });
      } else {
        dispatch("errors/createErrorMessage", e, { root: true });
      }
    }
  },
  async get({ commit, dispatch }, { path, object, parentName }) {
    try {
      const { data } = await api.get(path, object);
      commit("SET_OBJECTS_LIST", data);
      if (object) commit("SET_PARENT_ID", object);

      if (parentName) await commit("SET_PARENT_NAME", parentName);
      else await commit("SET_PARENT_NAME", undefined);
    } catch (e) {
      commit("CLEAR_STATE");
      if (e.response.status === 401) {
        dispatch("security/logout", null, { root: true });
      } else {
        dispatch("errors/createErrorMessage", e, { root: true });
      }
    }
  },
  async delete({ commit, dispatch }, { path, object }) {
    Vue.$confirm({
      message: "Вы действительно хотите удалить запись?",
      button: {
        yes: "Да",
        no: "Нет",
      },
      callback: (confirm) => {
        if (confirm) {
          try {
            api.delete(path, object);
            commit("DELETE_OBJECT", object);
          } catch (e) {
            if (e.response.status === 401) {
              dispatch("security/logout", null, { root: true });
            } else {
              dispatch("errors/createErrorMessage", e, { root: true });
            }
          }
        }
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
