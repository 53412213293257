import { connect } from "vuex-connect";
import CashierEdit from "./CashierEdit";

const URL = "/api/v1/cashier";

export default connect({
  stateToProps: {
    object: (state) => state.cashier.object,
    saved: (state) => state.cashier.saved,
  },
  methodsToEvents: {
    edit({ dispatch }, object) {
      dispatch("cashier/editObject", object);
    },
    persist({ dispatch }) {
      dispatch("cashier/persistObject", { path: URL });
    },
    cancelEdit({ dispatch }) {
      dispatch("cashier/cancelEdit");
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("cashier/getById", { id: this.$route.query.id });
      await dispatch("mainTitle/setTitle", "Редактирование кассы");
    },
  },
})("CashierEdit", CashierEdit);
