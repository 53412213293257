import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

import errors from "./modules/appErrorBanner.js";
import security from "./modules/security";
import mainTitle from "./modules/mainTitle";
import users from "./modules/store";
import cashier from "./modules/store";
import graph from "./modules/store";
import cardType from "./modules/store";
import cardTypeSaleSettings from "./modules/store";
import cardTypeBonusSettings from "./modules/store";
import attractionDevice from "./modules/store";
import attraction from "./modules/store";
import attractionSettings from "./modules/store";
import reportGroup from "./modules/store";
import system from "./modules/system";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    errors,
    security,
    mainTitle,
    users,
    graph,
    cashier,
    cardType,
    cardTypeSaleSettings,
    cardTypeBonusSettings,
    attractionDevice,
    attraction,
    attractionSettings,
    reportGroup,
    system,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
