import { connect } from "vuex-connect";
import CardTypeBonusSettingsEdit from "./CardTypeBonusSettingsEdit";

const URL = "/api/v1/cardTypeBonusSettings";

export default connect({
  stateToProps: {
    object: (state) => state.cardTypeBonusSettings.object,
    saved: (state) => state.cardTypeBonusSettings.saved,
    parentId: (state) => state.cardTypeBonusSettings.parentId,
  },
  methodsToEvents: {
    edit({ dispatch }, object) {
      dispatch("cardTypeBonusSettings/editObject", object);
    },
    persist({ dispatch }) {
      dispatch("cardTypeBonusSettings/persistObject", { path: URL });
    },
    cancelEdit({ dispatch }) {
      dispatch("cardTypeBonusSettings/cancelEdit");
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("cardTypeBonusSettings/getById", {
        parentId: this.$route.query.parentId,
        id: this.$route.query.id,
      });
      await dispatch("mainTitle/setTitle", "Редактирование настройки бонусов");
    },
  },
})("CardTypeBonusSettingsEdit", CardTypeBonusSettingsEdit);
