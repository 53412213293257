import router from "@/router";
import api from "@/api/security";
import axios from "axios";

const state = () => ({
  errorText: null,
  token: localStorage.getItem("token"),
  username: null,
  authorities: [],
});

const mutations = {
  SET_LOGIN_DATA(state, data) {
    state.token = data.token;
    state.errorText = null;
    state.username = data.username;
    state.authorities = data.authorities;
    localStorage.setItem("token", state.token);
    axios.defaults.headers.common["Authorization"] = "Bearer " + state.token;
  },
  SET_LOGOUT(state, data) {
    state.token = null;
    state.errorText = data;
    localStorage.removeItem("token");
  },
};

const actions = {
  async login({ commit }, { username, password }) {
    try {
      const { data } = await api.login({ username, password });
      commit("SET_LOGIN_DATA", data);
      await router.push("/dashboard");
    } catch (e) {
      if (e.response.status === 401) {
        commit("SET_LOGOUT", "Некорректный пользователь и пароль");
      }
    }
  },
  async logout({ commit }) {
    commit("SET_LOGOUT", null);
    await router.push("/login");
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
