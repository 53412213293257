<template>
  <v-container fluid>
    <TableBanner
      returnLink="Attraction"
      addLink="AttractionSettingsEdit"
      :parentId="parentId"
      :parentName="parentName"
    />
    <v-data-table
      :headers="headers"
      :items="objectList"
      class="transparent"
      dense
      disable-pagination
      hide-default-footer
      item-key="id"
      multi-sort
    >
      <template #[`item.action`]="{ item }">
        <v-btn
          :to="{
            name: 'AttractionSettingsEdit',
            query: { parentId: parentId, id: item.id },
          }"
          text
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn text @click="$emit('deleteItem', item)">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: {
    objectList: Array,
    parentId: String,
    parentName: String,
  },
  data: () => ({
    headers: [
      { text: "Тип карты", value: "cardTypeName" },
      { text: "График", value: "graphName" },
      { text: "Цена(руб)", value: "sumPrice" },
      { text: "Цена(бонусы)", value: "bonusPrice" },
      {
        text: "Редактирование Удаление",
        value: "action",
        sortable: false,
        align: "right",
      },
    ],
  }),
};
</script>
