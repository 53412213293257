<template>
  <v-container fluid>
    <FormBanner
      returnLink="AttractionDevice"
      :saved="saved"
      :valid="valid"
      @persist="$emit('persist')"
      @cancelEdit="$emit('cancelEdit')"
    />
    <v-container fluid>
      <v-form v-model="valid" ref="form">
        <v-text-field
          :value="item.ipAddress"
          label="IP адрес"
          outlined
          rounded
          :rules="requiredRule"
          @input="$emit('edit', { key: 'ipAddress', value: $event })"
        />
        <v-text-field
          :value="item.port"
          label="Порт"
          outlined
          rounded
          :rules="requiredRule"
          @input="$emit('edit', { key: 'port', value: $event })"
        />
        <v-text-field
          :value="item.deviceAddress"
          label="Устройство"
          outlined
          rounded
          :rules="requiredRule"
          @input="$emit('edit', { key: 'deviceAddress', value: $event })"
        />
        <v-text-field
          :value="item.modeCount"
          label="Количество режимов"
          outlined
          rounded
          type="number"
          min="0"
          inputmode="numeric"
          pattern="^[-\d]\d*$"
          step="1"
          @input="$emit('edit', { key: 'modeCount', value: $event })"
        />
        <v-text-field
          :value="item.maxEntranceCount"
          label="Максимум проходов"
          outlined
          rounded
          type="number"
          min="0"
          inputmode="numeric"
          pattern="^[-\d]\d*$"
          step="1"
          @input="$emit('edit', { key: 'maxEntranceCount', value: $event })"
        />
        <v-text-field
          :value="item.timeout"
          label="Задержка"
          outlined
          rounded
          type="number"
          min="0"
          inputmode="numeric"
          pattern="^[-\d]\d*$"
          step="1"
          @input="$emit('edit', { key: 'timeout', value: $event })"
        />
        <v-text-field
          :value="item.ledBrightness"
          label="Яркость"
          outlined
          rounded
          type="number"
          min="0"
          inputmode="numeric"
          pattern="^[-\d]\d*$"
          step="1"
          @input="$emit('edit', { key: 'ledBrightness', value: $event })"
        />
        <v-switch
          :input-value="item.indicateEntrance"
          label="Фиксация"
          @change="$emit('edit', { key: 'indicateEntrance', value: $event })"
        />
        <v-switch
          :input-value="item.typeRelay1"
          label="Рэле 1"
          @change="$emit('edit', { key: 'typeRelay1', value: $event })"
        />
        <v-switch
          :input-value="item.typeRelay2"
          label="Рэле 2"
          @change="$emit('edit', { key: 'typeRelay2', value: $event })"
        />
        <v-text-field
          :value="item.messageTime"
          label="Время сообщения"
          outlined
          rounded
          type="number"
          min="0"
          inputmode="numeric"
          pattern="^[-\d]\d*$"
          step="1"
          @input="$emit('edit', { key: 'messageTime', value: $event })"
        />
        <v-text-field
          :value="item.greenTime"
          label="Время зеленого индикатора"
          outlined
          rounded
          type="number"
          min="0"
          inputmode="numeric"
          pattern="^[-\d]\d*$"
          step="1"
          @input="$emit('edit', { key: 'greenTime', value: $event })"
        />
        <v-text-field
          :value="item.redTime"
          label="Время красного индикатора"
          outlined
          rounded
          type="number"
          min="0"
          inputmode="numeric"
          pattern="^[-\d]\d*$"
          step="1"
          @input="$emit('edit', { key: 'redTime', value: $event })"
        />
        <v-text-field
          :value="item.relayTime"
          label="Время рэле"
          outlined
          rounded
          type="number"
          min="0"
          inputmode="numeric"
          pattern="^[-\d]\d*$"
          step="1"
          @input="$emit('edit', { key: 'relayTime', value: $event })"
        />
        <v-text-field
          :value="item.relayInterval"
          label="Интервал рэле"
          outlined
          rounded
          type="number"
          min="0"
          inputmode="numeric"
          pattern="^[-\d]\d*$"
          step="1"
          @input="$emit('edit', { key: 'relayInterval', value: $event })"
        />
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import { requiredRule } from "@/validation/input-rules.js";
import _ from "lodash";

export default {
  props: {
    object: Object,
    saved: Boolean,
  },
  data: () => ({
    valid: false,
    requiredRule: [requiredRule.bind(this)],
  }),
  computed: {
    item: ({ object }) =>
      _.isEmpty(object)
        ? {
            id: null,
            ipAddress: null,
            port: null,
            deviceAddress: null,
            modeCount: 0,
            maxEntranceCount: 0,
            timeout: 0,
            ledBrightness: 0,
            indicateEntrance: false,
            typeRelay1: false,
            typeRelay2: false,
            messageTime: 0,
            greenTime: 0,
            redTime: 0,
            relayTime: 0,
            relayInterval: 0,
          }
        : object,
  },
};
</script>
