<template>
  <v-container fluid>
    <TableBanner addLink="CardTypeEdit" />
    <v-data-table
      :headers="headers"
      :items="objectList"
      class="transparent"
      dense
      disable-pagination
      hide-default-footer
      item-key="id"
      multi-sort
    >
      <template #[`item.action`]="{ item }">
        <v-btn :to="{ name: 'CardTypeEdit', query: { id: item.id } }" text>
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn text @click="$emit('deleteItem', item)">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </template>
      <template #[`item.mainType`]="{ item }">
        <v-simple-checkbox v-model="item.mainType" disabled></v-simple-checkbox>
      </template>

      <template #[`item.saleSettings`]="{ item }">
        <v-btn
          :to="{ name: 'CardTypeSaleSettings', query: { parentId: item.id } }"
          text
        >
          <v-icon>mdi-point-of-sale</v-icon>
        </v-btn>
      </template>
      <template #[`item.bonusSettings`]="{ item }">
        <v-btn
          :to="{ name: 'CardTypeBonusSettings', query: { parentId: item.id } }"
          text
        >
          <v-icon>mdi-countertop-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: {
    objectList: Array,
  },
  data: () => ({
    headers: [
      { text: "Наименование", value: "name" },
      { text: "Основной тип", value: "mainType" },
      {
        text: "Настройка продажи",
        value: "saleSettings",
        sortable: false,
        align: "right",
      },
      {
        text: "Настройка бонусов",
        value: "bonusSettings",
        sortable: false,
        align: "right",
      },
      {
        text: "Редактирование Удаление",
        value: "action",
        sortable: false,
        align: "right",
      },
    ],
  }),
};
</script>
