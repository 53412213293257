import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import RuLocale from "vuetify/es5/locale/ru";

Vue.use(Vuetify);

export const darkTheme = false;

const vuetify = new Vuetify({
  buttons: {
    capitalize: false,
  },
  theme: {
    dark: darkTheme,
  },
  lang: {
    locales: { RuLocale },
    current: "RuLocale",
  },
});

export default vuetify;
