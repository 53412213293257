<template>
  <v-app>
    <v-app-bar app color="white" dense elevation="0">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <MainTitle />
    </v-app-bar>

    <NavigationMenu v-model="drawer"></NavigationMenu>

    <v-main>
      <AppErrorBanner></AppErrorBanner>
      <vue-confirm-dialog></vue-confirm-dialog>
      <router-view />
    </v-main>

    <v-footer color="white" padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — ©iPark
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import NavigationMenu from "@/components/NavigationMenu";

export default {
  components: {
    NavigationMenu,
  },
  data: () => ({
    drawer: false,
  }),
};
</script>
