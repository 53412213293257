<template>
  <v-navigation-drawer
    :value="value"
    app
    clipped
    temporary
    @input="$emit('input', $event)"
  >
    <v-list dense nav>
      <v-list-item-group v-model="group">
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Панель управления</v-list-item-title>
        </v-list-item>

        <v-list-item to="/users">
          <v-list-item-icon>
            <v-icon>mdi-account-group-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Пользователи</v-list-item-title>
        </v-list-item>

        <v-list-item to="/cashier">
          <v-list-item-icon>
            <v-icon>mdi-cash-register</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Кассы</v-list-item-title>
        </v-list-item>

        <v-list-item to="/graph">
          <v-list-item-icon>
            <v-icon>mdi-chart-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Графики</v-list-item-title>
        </v-list-item>

        <v-list-item to="/cardType">
          <v-list-item-icon>
            <v-icon>mdi-card-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Типы карт</v-list-item-title>
        </v-list-item>

        <v-list-item to="/attractionDevice">
          <v-list-item-icon>
            <v-icon>mdi-devices</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Устройства</v-list-item-title>
        </v-list-item>

        <v-list-item to="/attraction">
          <v-list-item-icon>
            <v-icon>mdi-ferris-wheel</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Аттракционы</v-list-item-title>
        </v-list-item>

        <v-list-item to="/reportGroup">
          <v-list-item-icon>
            <v-icon>mdi-select-group</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Группы отчетности</v-list-item-title>
        </v-list-item>

        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-exit-run</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Выход</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavigationMenu",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    group: null,
  }),
  methods: {
    logout() {
      this.$store.dispatch("security/logout");
    },
  },
};
</script>
