import { connect } from "vuex-connect";
import AttractionEdit from "./AttractionEdit";

const URL = "/api/v1/attraction";

export default connect({
  stateToProps: {
    object: (state) => state.attraction.object,
    saved: (state) => state.attraction.saved,
    parentName: (state) => state.attraction.parentName,
  },
  methodsToEvents: {
    edit({ dispatch }, object) {
      dispatch("attraction/editObject", object);
    },
    persist({ dispatch }) {
      dispatch("attraction/persistObject", { path: URL });
    },
    cancelEdit({ dispatch }) {
      dispatch("attraction/cancelEdit");
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("attraction/getById", {
        id: this.$route.query.id,
        parentName: this.$route.query.parentName,
      });
      await dispatch("mainTitle/setTitle", "Редактирование аттракционов");
    },
  },
})("AttractionEdit", AttractionEdit);
