var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_c('v-combobox',{attrs:{"items":_vm.graphTypes,"value":_vm.getComboboxValue(_vm.item.graphType, this.graphTypes),"item-text":"name","item-value":"id","label":"Тип графика","outlined":"","rounded":""},on:{"input":function($event){_vm.sendGraphSettings({
            key: 'graphType',
            value: _vm.getComboboxId($event),
          })}}})],1),_c('v-card-text',[(_vm.item.graphType === 'YEAR' || _vm.item.graphType === 'MONTH')?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.item.graphType === 'YEAR' || _vm.item.graphType === 'MONTH')?_c('v-text-field',{attrs:{"value":_vm.item.startDay,"label":"Число месяца (с)","outlined":"","rounded":"","type":"number","min":"1","max":"31","inputmode":"numeric","pattern":"[0-9]*","rules":_vm.dayRules},on:{"input":function($event){return _vm.sendGraphSettings({ key: 'startDay', value: $event })}}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.item.graphType === 'YEAR' || _vm.item.graphType === 'MONTH')?_c('v-text-field',{attrs:{"value":_vm.item.endDay,"label":"Число месяца (по)","outlined":"","rounded":"","type":"number","min":"1","max":"31","inputmode":"numeric","pattern":"[0-9]*","rules":_vm.dayRules},on:{"input":function($event){return _vm.sendGraphSettings({ key: 'endDay', value: $event })}}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.item.graphType === 'YEAR')?_c('v-combobox',{attrs:{"items":_vm.months,"value":_vm.getComboboxValue(_vm.item.month, this.months),"item-text":"name","item-value":"id","label":"Месяц","outlined":"","rounded":""},on:{"input":function($event){_vm.sendGraphSettings({
                key: 'month',
                value: _vm.getComboboxId($event),
              })}}}):_vm._e()],1)],1):_vm._e(),(_vm.item.graphType === 'WEEK')?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.item.graphType === 'WEEK')?_c('v-combobox',{attrs:{"items":_vm.weekDays,"value":_vm.getComboboxValue(_vm.item.startWeekday, this.weekDays),"item-text":"name","item-value":"id","label":"День недели (с)","outlined":"","rounded":""},on:{"input":function($event){_vm.sendGraphSettings({
                key: 'startWeekday',
                value: _vm.getComboboxId($event),
              })}}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.item.graphType === 'WEEK')?_c('v-combobox',{attrs:{"items":_vm.weekDays,"value":_vm.getComboboxValue(_vm.item.endWeekday, this.weekDays),"item-text":"name","item-value":"id","label":"День недели (по)","outlined":"","rounded":""},on:{"input":function($event){_vm.sendGraphSettings({
                key: 'endWeekday',
                value: _vm.getComboboxId($event),
              })}}}):_vm._e()],1)],1):_vm._e(),(_vm.item.graphType === 'INTERVAL')?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.item.graphType === 'INTERVAL')?_c('v-text-field',{attrs:{"value":_vm.item.startDate,"label":"Дата (с)","outlined":"","rounded":"","type":"date","rules":_vm.requiredRules},on:{"input":function($event){return _vm.sendGraphSettings({ key: 'startDate', value: $event })}}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.item.graphType === 'INTERVAL')?_c('v-text-field',{attrs:{"value":_vm.item.endDate,"label":"Дата (по)","outlined":"","rounded":"","type":"date","rules":_vm.requiredRules},on:{"input":function($event){return _vm.sendGraphSettings({ key: 'endDate', value: $event })}}}):_vm._e()],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"value":_vm.item.startTime,"label":"Время (с)","outlined":"","rounded":"","type":"time","rules":_vm.requiredRules},on:{"input":function($event){return _vm.sendGraphSettings({ key: 'startTime', value: $event + ':00' })}}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"value":_vm.item.endTime,"label":"Время (по)","outlined":"","rounded":"","clearable":"","type":"time"},on:{"input":function($event){return _vm.sendGraphSettings({
                key: 'endTime',
                value: $event != null ? $event + ':00' : null,
              })}}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-btn',{attrs:{"outlined":"","rounded":""},on:{"click":function($event){return _vm.deleteGraphSettings('new')}}},[_c('v-icon',[_vm._v("mdi-delete-outline")]),_c('span',[_vm._v("Удалить настройку")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }