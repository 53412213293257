import { connect } from "vuex-connect";
import CardTypeSaleSettings from "./CardTypeSaleSettings";

const URL = "/api/v1/cardTypeSaleSettings";

export default connect({
  stateToProps: {
    objectList: (state) => state.cardTypeSaleSettings.objects,
    parentId: (state) => state.cardTypeSaleSettings.parentId,
  },
  methodsToEvents: {
    deleteItem: function ({ dispatch }, item) {
      dispatch("cardTypeSaleSettings/delete", { path: URL, object: item });
    },
  },
  lifecycle: {
    mounted: async function ({ dispatch }) {
      await dispatch("cardTypeSaleSettings/get", {
        path: URL,
        object: this.$route.query.parentId,
      });
      await dispatch("mainTitle/setTitle", "Настройка продажи");
    },
  },
})("CardTypeSaleSettings", CardTypeSaleSettings);
