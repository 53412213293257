<template>
  <v-container fluid>
    <FormBanner
      returnLink="Users"
      :saved="saved"
      :valid="valid"
      @persist="$emit('persist')"
      @cancelEdit="$emit('cancelEdit')"
    />

    <v-container fluid>
      <v-form v-model="valid" ref="form">
        <v-text-field
          :value="item.username"
          label="Пользователь"
          outlined
          rounded
          :rules="requiredRule"
          @input="$emit('edit', { key: 'username', value: $event })"
        />
        <v-combobox
          :items="authority"
          :value="getAuthorityValue(item.authorities)"
          item-text="name"
          item-value="id"
          label="Разрешения"
          multiple
          outlined
          rounded
          @input="
            $emit('edit', {
              key: 'authorities',
              value: getAuthorityIds($event),
            })
          "
        />
        <v-text-field
          :value="item.lastName"
          label="Фамилия"
          outlined
          rounded
          @input="$emit('edit', { key: 'lastName', value: $event })"
        />
        <v-text-field
          :value="item.firstName"
          label="Имя"
          outlined
          rounded
          :rules="requiredRule"
          @input="$emit('edit', { key: 'firstName', value: $event })"
        />
        <v-text-field
          :value="item.middleName"
          label="Отчество"
          outlined
          rounded
          @input="$emit('edit', { key: 'middleName', value: $event })"
        />
        <v-text-field
          :value="item.password"
          label="Пароль"
          outlined
          rounded
          type="password"
          @input="$emit('edit', { key: 'password', value: $event })"
        />
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import { requiredRule } from "@/validation/input-rules.js";
import _ from "lodash";

export default {
  props: {
    object: Object,
    saved: Boolean,
  },
  data: () => ({
    authority: [
      { id: "ADMIN", name: "Администратор" },
      { id: "CASHIER", name: "Кассир" },
      { id: "CASHIER_ADMIN", name: "Админская касса" },
      { id: "CASHIER_MAIN", name: "Старший кассир" },
      { id: "CARD_EDIT", name: "Редактирование карты" },
      { id: "REPORT_CARD", name: "Отчет по карте" },
      { id: "REPORT_ENTRANCE", name: "Отчет по проходам" },
      { id: "REPORT_CASHIER", name: "Отчет по кассирам" },
      { id: "IIKO", name: "Кафе IIKO" },
      { id: "REPORT_IIKOTERMINAL", name: "Отчет по iiko-терминалам" },
      { id: "MOBILE_OPERATOR", name: "Мобильный оператор" },
      { id: "REPORT_CASHIER_TESTMODE", name: "Отчет по кассовым аппаратам Атол и Штрих-М" },
    ],
    valid: false,
    requiredRule: [requiredRule.bind(this)],
  }),
  computed: {
    item: ({ object }) =>
      _.isEmpty(object)
        ? {
            id: null,
            username: null,
            lastName: null,
            firstName: null,
            middleName: null,
            password: null,
            authorities: null,
          }
        : object,
  },
  methods: {
    getAuthorityValue(authorityIds) {
      return authorityIds != null && authorityIds.length > 0
        ? this.authority.filter((a) => authorityIds.includes(a.id))
        : [];
    },
    getAuthorityIds(authorities) {
      return authorities.map((obj) => {
        return obj.id;
      });
    },
  },
};
</script>
