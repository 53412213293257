import axios from "axios";

const urls = {
  LOGIN: "/login",
};

export default {
  async login({ username, password }) {
    return await axios.post(urls.LOGIN, { username, password });
  },
};
