const state = () => ({
  showMessage: false,
  messagesArray: [],
});

const getters = {};

const mutations = {
  setError(state, message) {
    if (state.showMessage) state.messagesArray.push(message);
    else state.messagesArray = [message];
    state.showMessage = true;
  },
  clearError(state) {
    state.showMessage = false;
    state.messagesArray = [];
  },
};

const actions = {
  createErrorMessage({ commit }, errorData) {
    let errorMessage = "";

    if (errorData.response.data?.message) {
      errorMessage = errorData.response.data.message;
    } else {
      errorMessage = errorData.toString();
    }

    if (errorData.response.data?.code) {
      errorMessage = errorData.response.data.code + ": " + errorMessage;
    }

    commit("setError", errorMessage);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
