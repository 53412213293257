<template>
  <v-container fluid>
    <TableBanner addLink="AttractionEdit" />

    <v-switch v-model="showDisabled" label="Отображать выключенные" />

    <v-data-table
      :headers="headers"
      :items="objectList"
      class="transparent"
      dense
      disable-pagination
      hide-default-footer
      item-key="id"
      multi-sort
    >
      <template #[`item.enabled`]="{ item }">
        <v-simple-checkbox v-model="item.enabled" disabled></v-simple-checkbox>
      </template>

      <template #[`item.action`]="{ item }">
        <v-btn
          :to="{
            name: 'AttractionEdit',
            query: { id: item.id, parentName: item.name },
          }"
          text
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn text @click="$emit('deleteItem', item)">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </template>

      <template #[`item.attractionSettings`]="{ item }">
        <v-btn
          :to="{
            name: 'AttractionSettings',
            query: { parentId: item.id, parentName: item.name },
          }"
          text
        >
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: {
    objectList: Array,
  },
  data: () => ({
    showDisabled: false,
  }),
  computed: {
    headers() {
      return [
        { text: "Наименование", value: "name" },
        { text: "IP адрес", value: "attractionDeviceIpAddress" },
        { text: "Порт", value: "attractionDevicePort" },
        { text: "Устройство", value: "attractionDeviceDeviceAddress" },
        { text: "Режим", value: "mode" },
        {
          text: "Включен",
          value: "enabled",
          filter: (value) => {
            if (this.showDisabled) return true;
            return value === true;
          },
        },
        {
          text: "Настройка аттракциона",
          value: "attractionSettings",
          sortable: false,
          align: "right",
        },
        {
          text: "Редактирование Удаление",
          value: "action",
          sortable: false,
          align: "right",
        },
      ];
    },
  },
};
</script>
