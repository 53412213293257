<template>
  <v-container fluid>
    <FormBanner
      returnLink="ReportGroup"
      :saved="saved"
      :valid="valid"
      @persist="$emit('persist')"
      @cancelEdit="$emit('cancelEdit')"
    />
    <v-container fluid>
      <v-form v-model="valid" ref="form">
        <v-text-field
          :value="item.name"
          label="Наименование"
          outlined
          rounded
          :rules="requiredRule"
          @input="$emit('edit', { key: 'name', value: $event })"
        />
        <v-row>
          <v-col cols="12" md="6">
            <UserTableMultiSelect
              :users="item.users"
              @selectUser="$emit('edit', { key: 'users', value: $event })"
            />
          </v-col>
          <v-col cols="12" md="6">
            <AttractionTableMultiSelect
              :attractions="item.attractions"
              @selectAttraction="
                $emit('edit', { key: 'attractions', value: $event })
              "
            />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import { requiredRule } from "@/validation/input-rules.js";
import _ from "lodash";

export default {
  props: {
    object: Object,
    saved: Boolean,
  },
  data: () => ({
    valid: false,
    requiredRule: [requiredRule.bind(this)],
  }),
  computed: {
    item: ({ object }) =>
      _.isEmpty(object)
        ? {
            id: null,
            name: null,
            attractions: [],
            users: [],
          }
        : object,
  },
};
</script>
